const Resources = {
  url: "https://api.therelate.app",
  admin: {
    name: "Relate Team",
    email: "team@therleate.app",
    badge: "Owner",
  },
  app: "Relate",
};
export default Resources;
