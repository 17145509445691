class Functions {
  static async sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  static slugify(text) {
    text = text.replace(/^\s+|\s+$/g, "");
    text = text.toLowerCase();
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      text = text.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    text = text
      .replace(/[^a-z0-9 -]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");

    return text;
  }
}

export default Functions;
