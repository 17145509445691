import Assistant from "./Assistant";
import { AssetEditor } from "../Components";

const System = {
  initial: {
    screen: "",
    entity: false,
    temporary: false,
    override: false,
  },
  definitions: {
    questions: "Questions",
    feedbacks: "Feedbacks",
    users: "Users",
    forms: "Forms",
    form_inputs: "Inputs",
    steps: "Steps",
    studies: "Studies",
    journeys: "Journeys",
    answers: "Answers",
    step_tasks: "Tasks",
    study_tasks: "Tasks",
    directions: "Gratitude",
  },
  menu: [
    Assistant.menu.bitter("Journeys", "journeys"),
    Assistant.menu.bitter("Questions", "questions"),
    Assistant.menu.bitter("Feedbacks", "feedbacks"),
    Assistant.menu.bitter("Users", "users"),
    Assistant.menu.bitter("Forms", "forms"),
    Assistant.menu.bitter("Gratitude", "directions"),
  ],
  entities: {
    questions: Assistant.entities(
      "questions",
      "Questions",
      ["create"],
      [
        Assistant.column(
          "Question",
          (entity) => entity.parameters.question,
          "identifier"
        ),
      ]
    ),
    directions: Assistant.entities(
      "directions",
      "Gratitude",
      ["create"],
      [
        Assistant.column(
          "Question",
          (entity) => entity.parameters.direction,
          "identifier"
        ),
        Assistant.column("Order", (entity) => entity.parameters.order),
      ],
      false,
      "none",
      false,
      (a, b) => {
        if (a.parameters.order < b.parameters.order) {
          return -1;
        }
        if (a.parameters.order > b.parameters.order) {
          return 1;
        }
        return 0;
      }
    ),
    answers: Assistant.entities(
      "answers",
      "Answers",
      [],
      [
        Assistant.column(
          "Answer",
          (entity) => entity.parameters.answer,
          "identifier"
        ),
        Assistant.column("Date", (entity) => entity.parameters.date),
      ]
    ),
    feedbacks: Assistant.entities(
      "feedbacks",
      "Feedbacks",
      [],
      [
        Assistant.column(
          "Comment",
          (entity) => entity.parameters.comment,
          "identifier"
        ),
        Assistant.column(
          "Stars",
          (entity) => entity.parameters.stars,
          "number"
        ),
        Assistant.column(
          "User",
          (entity) => entity.parameters.user,
          "entity",
          (entity) => {
            return {
              api: "users",
            };
          }
        ),
        Assistant.column(
          "Step",
          (entity) => entity.parameters.step,
          "entity",
          (entity) => {
            return {
              api: "steps",
            };
          }
        ),
      ],
      false,
      "none",
      [
        { key: "order", value: "DESC" },
        { key: "parameters", value: { comment: "" } },
        { key: "filter", value: { comment: "NOT" } },
      ]
    ),
    users: Assistant.entities(
      "users",
      "Users",
      [],
      [
        Assistant.column(
          "Device",
          (entity) => entity.parameters.device,
          "identifier"
        ),
        Assistant.column(
          "Platform",
          (entity) => entity.parameters.platform,
          "string"
        ),
        Assistant.column(
          "Premium",
          (entity) => (entity.parameters.premium ? "Evet" : "Hayır"),
          "string"
        ),
      ]
    ),
    forms: Assistant.entities(
      "forms",
      "Forms",
      ["create"],
      [
        Assistant.column(
          "Title",
          (entity) => entity.parameters.title,
          "identifier"
        ),
      ]
    ),
    form_inputs: Assistant.entities(
      "form_inputs",
      "Inputs",
      ["create", "update"],
      [
        Assistant.column(
          "Label",
          (entity) => entity.parameters.label,
          "identifier"
        ),
      ]
    ),
    applications: Assistant.entities(
      "applications",
      "Applications",
      [],
      [
        Assistant.column(
          "Title",
          (entity) => entity.parameters.title,
          "identifier"
        ),
        Assistant.column("Date", (entity) => entity.parameters.date, "string"),
      ]
    ),
    application_inputs: Assistant.entities(
      "application_inputs",
      "Inputs",
      [],
      [
        Assistant.column(
          "Content",
          (entity) => JSON.stringify(entity.parameters.content),
          "string"
        ),
        Assistant.column(
          "Input",
          (entity) => entity.parameters.input,
          "entity",
          (entity) => {
            return {
              api: "form_inputs",
            };
          }
        ),
      ]
    ),
    steps: Assistant.entities(
      "steps",
      "Steps",
      ["create", "update"],
      [
        Assistant.column(
          "Title",
          (entity) => entity.parameters.title,
          "identifier"
        ),
        Assistant.column("Group", (entity) => entity.parameters.group),
        Assistant.column("Order", (entity) => entity.parameters.order),
      ]
    ),
    studies: Assistant.entities(
      "studies",
      "Studies",
      ["create", "update"],
      [
        Assistant.column(
          "Title",
          (entity) => entity.parameters.title,
          "identifier"
        ),
        Assistant.column("Group", (entity) => entity.parameters.group),
      ]
    ),
    journeys: Assistant.entities(
      "journeys",
      "Journeys",
      ["create", "update"],
      [
        Assistant.column(
          "Title",
          (entity) => entity.parameters.title,
          "identifier"
        ),
        Assistant.column("Order", (entity) => entity.parameters.order),
      ],
      false,
      "none",
      false,
      (a, b) => {
        if (a.parameters.order < b.parameters.order) {
          return -1;
        }
        if (a.parameters.order > b.parameters.order) {
          return 1;
        }
        return 0;
      }
    ),
    step_tasks: Assistant.entities(
      "step_tasks",
      "Tasks",
      ["create", "update"],
      [
        Assistant.column(
          "Title",
          (entity) => entity.parameters.title,
          "identifier"
        ),
      ]
    ),
    study_tasks: Assistant.entities(
      "study_tasks",
      "Tasks",
      ["create", "update"],
      [
        Assistant.column(
          "Title",
          (entity) => entity.parameters.title,
          "identifier"
        ),
      ]
    ),
  },
  entity: {
    questions: Assistant.entity(
      "questions",
      (entity) => entity.uuid,
      ["update", "delete"],
      [
        Assistant.block.string("question", "Question"),
        Assistant.block.select("type", "Type", [
          { label: "String", value: "string" },
          { label: "Select", value: "select" },
        ]),
        Assistant.block.select(
          "other",
          "Other Input Enabled",
          [{ label: "Evet", value: true }],
          (entity) => {
            return {
              display: entity.parameters.type === "select",
            };
          }
        ),
        Assistant.block.select(
          "multiple",
          "Multiple Selection Enabled",
          [{ label: "Evet", value: true }],
          (entity) => {
            return {
              display: entity.parameters.type === "select",
            };
          }
        ),
        Assistant.block.d2("options", "Options", (entity) => {
          return {
            display: entity.parameters.type === "select",
          };
        }),
        Assistant.block.entities("Answers", (parent) => {
          return {
            display: true,
            create: false,
            api: "answers",
            filter: (entity) =>
              entity.parameters.question === parent.uuid &&
              entity.parameters.answer.length > 0,
            filtering: [
              {
                key: "parameters",
                value: { question: parent.uuid },
              },
            ],
          };
        }),
      ]
    ),
    directions: Assistant.entity(
      "directions",
      (entity) => entity.uuid,
      ["update", "delete"],
      [Assistant.block.string("direction", "Question")]
    ),
    answers: Assistant.entity(
      "answers",
      (entity) => entity.uuid,
      ["update", "delete"],
      [Assistant.block.string("answer", "Answer")]
    ),
    feedbacks: Assistant.entity(
      "feedbacks",
      (entity) => entity.uuid,
      ["delete"],
      [
        Assistant.block.string("comment", "Comment"),
        Assistant.block.entity("user", "User", (entity) => {
          return {
            api: "users",
            create: false,
            update: false,
            label: (entity) => entity.uuid,
            change: false,
            display: true,
          };
        }),
        Assistant.block.entity("step", "Step", (entity) => {
          return {
            api: "steps",
            create: false,
            update: false,
            label: (entity) => entity.uuid,
            change: false,
            display: true,
          };
        }),
      ]
    ),
    users: Assistant.entity(
      "users",
      (entity) => entity.uuid,
      ["delete", "update"],
      [
        Assistant.block.string("device", "Device"),
        Assistant.block.string("platform", "Platform"),
        Assistant.block.select("premium", "Premium", [
          { label: "Evet", value: true },
        ]),
      ]
    ),
    forms: Assistant.entity(
      "forms",
      (entity) => entity.uuid,
      ["delete", "update"],
      [
        Assistant.block.string("title", "Title"),
        Assistant.block.select("type", "Type", [
          { label: "Giriş Formu", value: "auth" },
        ]),
        Assistant.block.entities("Inputs", (parent) => {
          return {
            display: true,
            create: true,
            api: "form_inputs",
            override: {
              form: parent.uuid,
            },
            filter: (entity) => entity.parameters.form === parent.uuid,
            filtering: [
              {
                key: "parameters",
                value: { form: parent.uuid },
              },
            ],
          };
        }),
        Assistant.block.entities("Answers", (parent) => {
          return {
            display: true,
            create: true,
            api: "applications",
            override: {
              form: parent.uuid,
            },
            filter: (entity) => entity.parameters.form === parent.uuid,
            filtering: [
              {
                key: "parameters",
                value: { form: parent.uuid },
              },
            ],
          };
        }),
      ]
    ),
    form_inputs: Assistant.entity(
      "form_inputs",
      (entity) => entity.uuid,
      ["delete", "update"],
      [
        Assistant.block.select("type", "Type", [
          { label: "String", value: "string" },
          { label: "Select", value: "select" },
        ]),
        Assistant.block.select(
          "other",
          "Other Input Enabled",
          [{ label: "Evet", value: true }],
          (entity) => {
            return {
              display: entity.parameters.type === "select",
            };
          }
        ),
        Assistant.block.select(
          "multiple",
          "Multiple Selection Enabled",
          [{ label: "Evet", value: true }],
          (entity) => {
            return {
              display: entity.parameters.type === "select",
            };
          }
        ),
        Assistant.block.d2("options", "Options", (entity) => {
          return {
            display: entity.parameters.type === "select",
          };
        }),
        Assistant.block.string("label", "Label"),
        Assistant.block.string("description", "Description"),
        Assistant.block.d2("collect", "Collect", (entity) => {
          return {
            display: true,
          };
        }),
      ]
    ),
    applications: Assistant.entity(
      "applications",
      (entity) => entity.uuid,
      [],
      [
        Assistant.block.entities("Inputs", (parent) => {
          return {
            display: true,
            create: false,
            details: false,
            api: "application_inputs",
            override: {
              application: parent.uuid,
            },
            filter: (entity) => entity.parameters.application === parent.uuid,
            filtering: [
              {
                key: "parameters",
                value: { application: parent.uuid },
              },
            ],
          };
        }),
      ]
    ),
    application_inputs: Assistant.entity(
      "application_inputs",
      (entity) => entity.uuid,
      ["delete"],
      [Assistant.block.string("content", "Content")]
    ),
    journeys: Assistant.entity(
      "journeys",
      (entity) => entity.uuid,
      ["delete", "update"],
      [
        Assistant.block.custom((entity, system) => (
          <AssetEditor entity={entity} system={system} />
        )),
        Assistant.block.select("visible", "Visible", [
          { label: "Evet", value: true },
          { label: "Hayır", value: false },
        ]),
        Assistant.block.string("title", "Title"),
        Assistant.block.long("description", "Description"),
        Assistant.block.string("category", "Category"),
        Assistant.block.number("order", "Order"),
        Assistant.block.d2("colors", "Colors", (entity) => {
          return {
            create: false,
            display: true,
            trash: false,
            move: false,
            labels: [
              "Top Gradient Color",
              "Bottom Gradient Color",
              "Text Color",
            ],
          };
        }),
        Assistant.block.d1("groups", "Groups", (entity) => {
          return {
            display: true,
            labels: {
              group: "Group Number",
              title: "Title",
              description: "Description",
            },
          };
        }),
        Assistant.block.entities("Steps", (parent) => {
          return {
            display: true,
            create: true,
            api: "steps",
            override: {
              journey: parent.uuid,
            },
            filter: (entity) => entity.parameters.journey === parent.uuid,
            filtering: [
              {
                key: "parameters",
                value: { journey: parent.uuid },
              },
            ],
            order: [
              (a, b) => {
                if (a.parameters.order < b.parameters.order) {
                  return -1;
                }
                if (a.parameters.order > b.parameters.order) {
                  return 1;
                }
                return 0;
              },
              (a, b) => {
                if (a.parameters.group < b.parameters.group) {
                  return -1;
                }
                if (a.parameters.group > b.parameters.group) {
                  return 1;
                }
                return 0;
              },
            ],
          };
        }),
        Assistant.block.entities("Studies", (parent) => {
          return {
            display: true,
            create: true,
            api: "studies",
            override: {
              journey: parent.uuid,
            },
            filter: (entity) => entity.parameters.journey === parent.uuid,
            filtering: [
              {
                key: "parameters",
                value: { journey: parent.uuid },
              },
            ],
          };
        }),
      ]
    ),
    steps: Assistant.entity(
      "steps",
      (entity) => entity.uuid,
      ["delete", "update"],
      [
        Assistant.block.string("title", "Title"),
        Assistant.block.long("description", "Description"),
        Assistant.block.long("source", "Source"),
        Assistant.block.string("group", "Group"),
        Assistant.block.string("order", "Order"),
        Assistant.block.entities("Tasks", (parent) => {
          return {
            display: true,
            create: true,
            api: "step_tasks",
            override: {
              step: parent.uuid,
            },
            filter: (entity) => entity.parameters.step === parent.uuid,
            filtering: [
              {
                key: "parameters",
                value: { step: parent.uuid },
              },
            ],
          };
        }),
        Assistant.block.editor("content", "Content"),
        Assistant.block.long("note", "End Note"),
      ]
    ),
    studies: Assistant.entity(
      "studies",
      (entity) => entity.uuid,
      ["delete", "update"],
      [
        Assistant.block.string("title", "Title"),
        Assistant.block.long("description", "Description"),
        Assistant.block.long("source", "Source"),
        Assistant.block.string("group", "Group"),
        Assistant.block.entities("Tasks", (parent) => {
          return {
            display: true,
            create: true,
            api: "study_tasks",
            override: {
              study: parent.uuid,
            },
            filter: (entity) => entity.parameters.study === parent.uuid,
            filtering: [
              {
                key: "parameters",
                value: { study: parent.uuid },
              },
            ],
          };
        }),
        Assistant.block.editor("content", "Content"),
      ]
    ),
    step_tasks: Assistant.entity(
      "step_tasks",
      (entity) => entity.uuid,
      ["delete", "update"],
      [
        Assistant.block.string("title", "Title"),
        Assistant.block.long("description", "Description"),
        Assistant.block.string("tag", "Tag"),
        Assistant.block.select("optional", "Optional", [
          { label: "Evet", value: true },
        ]),
        Assistant.block.select("icon", "Icon", [
          { label: "None", value: "" },
          { label: "Home", value: "Home" },
          { label: "Settings", value: "Settings" },
          { label: "Journey", value: "Journey" },
          { label: "Heart", value: "Heart" },
          { label: "Smile", value: "Smile" },
          { label: "Task", value: "Task" },
          { label: "Star", value: "Star" },
          { label: "Check", value: "Check" },
          { label: "Music", value: "Music" },
          { label: "Back", value: "Back" },
          { label: "Lock", value: "Lock" },
          { label: "Down", value: "Down" },
          { label: "Up", value: "Up" },
          { label: "Movie", value: "Movie" },
          { label: "Netflix", value: "Netflix" },
          { label: "Microphone", value: "Microphone" },
          { label: "Text", value: "Text" },
          { label: "SpaFlower", value: "SpaFlower" },
          { label: "RiceBowl", value: "RiceBowl" },
        ]),
      ]
    ),
    study_tasks: Assistant.entity(
      "study_tasks",
      (entity) => entity.uuid,
      ["delete", "update"],
      [
        Assistant.block.string("title", "Title"),
        Assistant.block.long("description", "Description"),
        Assistant.block.string("tag", "Tag"),
        Assistant.block.select("optional", "Optional", [
          { label: "Evet", value: true },
        ]),
        Assistant.block.select("icon", "Icon", [
          { label: "None", value: "" },
          { label: "Home", value: "Home" },
          { label: "Settings", value: "Settings" },
          { label: "Journey", value: "Journey" },
          { label: "Heart", value: "Heart" },
          { label: "Smile", value: "Smile" },
          { label: "Task", value: "Task" },
          { label: "Star", value: "Star" },
          { label: "Check", value: "Check" },
          { label: "Music", value: "Music" },
          { label: "Back", value: "Back" },
          { label: "Lock", value: "Lock" },
          { label: "Down", value: "Down" },
          { label: "Up", value: "Up" },
          { label: "Movie", value: "Movie" },
          { label: "Netflix", value: "Netflix" },
          { label: "Microphone", value: "Microphone" },
          { label: "Text", value: "Text" },
          { label: "SpaFlower", value: "SpaFlower" },
          { label: "RiceBowl", value: "RiceBowl" },
        ]),
      ]
    ),
  },
};

export default System;
