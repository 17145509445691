import React from "react";
import { Store } from "./Modules";
import { Resources } from "./Helpers";
import { DashboardContainer, AuthenticationContainer } from "./Containers";
import Helmet from "react-helmet";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";

class App extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: false,
    };
  }

  componentDidMount() {}

  __auth = () => {
    this.setState({ authenticated: true });
  };

  render() {
    const { authenticated } = this.state;
    return (
      <Provider store={Store}>
        <Helmet>
          <title>
            {Resources.app} Beta - Welcome Back {Resources.admin.name}
          </title>
        </Helmet>
        {authenticated ? (
          <DashboardContainer />
        ) : (
          <AuthenticationContainer auth={this.__auth} />
        )}
        <Toaster />
      </Provider>
    );
  }
}

export default App;
