import React from "react";

import { Colors } from "../../Helpers";

const Component = ({ value, change, options }) => {
  return (
    <select
      style={{
        height: 40,
        border: "1.5px solid #F7F7F7",
        borderRadius: 2,
        boxSizing: "border-box",
        width: "100%",
        display: "block",
        paddingLeft: 12,
        paddingRight: 12,
        fontSize: 14,
        fontFamily: "Programme Regular",
        color: Colors.black,
        outline: "none",
        background: "none",
        backgroundColor: "none",
      }}
      value={value}
      onChange={(event) => {
        if (change) {
          if (typeof value === "boolean") {
            change(event.target.value === "" ? false : event.target.value);
          } else {
            change(event.target.value);
          }
        }
      }}
    >
      {options.map((option) => (
        <option selected={value == option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default Component;
