import React from "react";

import { Colors } from "../../Helpers";
import { Box, Icon } from "../../Bitter";

const Component = ({ value, change }) => {
  return (
    <Box display="flex" align="center">
      <Icon icon="search" size={16} right={8} opacity={0.5} />
      <input
        style={{
          padding: 0,
          margin: 0,
          width: "100%",
          flex: 1,
          display: "block",
          fontSize: 14,
          fontFamily: "Programme Regular",
          color: Colors.black,
          opacity: 0.5,
          outline: "none",
          background: "none",
          backgroundColor: "none",
          border: "none",
          lineHeight: "10px",
          height: 16
        }}
        placeholder="Ara..."
        value={value}
        onChange={(event) => (change ? change(event.target.value) : {})}
      />
    </Box>
  );
};

export default Component;
