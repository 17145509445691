import Lodash from "lodash";

const CALLBACKS = {
  string: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  long: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  editor: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  number: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = isNaN(value)
      ? parseFloat(value, 10) || 0 > parseInt(value, 10) || 0
        ? parseFloat(value, 10) || 0
        : parseInt(value, 10) || 0
      : value;
    return clone;
  },
  select: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  text: (entity) => entity,
  date: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  entity: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  d1: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  d2: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  d3: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
  d4: (entity, key, value) => {
    let clone = Lodash.clone(entity);
    clone.parameters[key] = value;
    return clone;
  },
};

const BLOCKS = {
  string: (key, label, rules = false, change = false) => {
    return {
      type: "string",
      key,
      label,
      value: (entity) => entity.parameters[key],
      rules: (entity) => (rules ? rules(entity) : false),
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.string(entity, key, value),
    };
  },
  long: (key, label, rules = false, change = false) => {
    return {
      type: "long",
      key,
      label,
      value: (entity) => entity.parameters[key],
      rules: (entity) => (rules ? rules(entity) : false),
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.long(entity, key, value),
    };
  },
  editor: (key, label, rules = false, change = false) => {
    return {
      type: "editor",
      key,
      label,
      value: (entity) => entity.parameters[key],
      rules: (entity) => (rules ? rules(entity) : false),
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.editor(entity, key, value),
    };
  },
  number: (key, label, rules = false, change = false) => {
    return {
      type: "number",
      key,
      label,
      value: (entity) => entity.parameters[key],
      rules: (entity) => (rules ? rules(entity) : false),
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.string(entity, key, value),
    };
  },
  select: (key, label, options = [], rules = false, change = false) => {
    return {
      type: "select",
      key,
      label,
      value: (entity) => entity.parameters[key],
      options: [{ label: "Hiçbiri", value: "" }, ...options],
      rules: (entity) => (rules ? rules(entity) : false),
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.string(entity, key, value),
    };
  },
  text: (key, label, rules = false, value = false) => {
    return {
      type: "text",
      key,
      label,
      rules: (entity) => (rules ? rules(entity) : false),
      value: value ? value : (entity) => entity.parameters[key],
    };
  },
  date: (key, label, rules = false, change = false) => {
    return {
      type: "date",
      key,
      label,
      rules: (entity) => (rules ? rules(entity) : false),
      value: (entity) => entity.parameters[key],
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.string(entity, key, value),
    };
  },
  entity: (key, label, rules = false, change = false) => {
    return {
      type: "entity",
      key,
      label,
      rules: (entity) => (rules ? rules(entity) : false),
      value: (entity) => entity.parameters[key],
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.string(entity, key, value),
    };
  },
  d1: (key, label, rules = false, change = false) => {
    return {
      type: "d1",
      key,
      label,
      rules: (entity) => (rules ? rules(entity) : false),
      value: (entity) => entity.parameters[key],
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.d1(entity, key, value),
    };
  },
  d2: (key, label, rules = false, change = false) => {
    return {
      type: "d2",
      key,
      label,
      rules: (entity) => (rules ? rules(entity) : false),
      value: (entity) => entity.parameters[key],
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.d2(entity, key, value),
    };
  },
  d3: (key, label, rules = false, change = false) => {
    return {
      type: "d3",
      key,
      label,
      rules: (entity) => (rules ? rules(entity) : false),
      value: (entity) => entity.parameters[key],
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.d3(entity, key, value),
    };
  },
  d4: (key, label, rules = false, change = false) => {
    return {
      type: "d4",
      key,
      label,
      rules: (entity) => (rules ? rules(entity) : false),
      value: (entity) => entity.parameters[key],
      change: change
        ? change
        : (entity, key, value) => CALLBACKS.d4(entity, key, value),
    };
  },
  entities: (label, rules) => {
    return {
      type: "entities",
      label,
      rules: (entity) => (rules ? rules(entity) : false),
    };
  },
  custom: (jsx) => {
    return {
      type: "custom",
      jsx,
    };
  },
};

const MENUS = {
  bitter: (label, value, icon = false) => {
    return { label, value, icon };
  },
};

const RULES = {
  rules: (rules) => {
    let temporary = {
      create: false,
      read: false,
      update: false,
      delete: false,
    };

    rules.map((rule) => (temporary[rule] = true));

    return temporary;
  },
};

const Assistant = {
  block: BLOCKS,
  menu: MENUS,
  tab: (label, value, blocks) => {
    return {
      label,
      value,
      blocks,
    };
  },
  column: (label, value, type = "string", rules = false) => {
    return {
      label,
      value: value ? (entity) => value(entity) : false,
      rules: (entity) => (rules ? rules(entity) : false),
      type,
    };
  },
  entities: (
    api,
    label,
    rules = [],
    columns = [],
    temporary = false,
    mode = "none",
    override = false,
    order = false
  ) => {
    return {
      api,
      label,
      columns,
      rules: RULES.rules(rules),
      temporary,
      override,
      order,
    };
  },
  entity: (
    api,
    label,
    rules = [],
    values = [],
    temporary = false,
    mode = "none"
  ) => {
    return {
      api,
      label,
      rules: RULES.rules(rules),
      blocks: mode === "none" ? values : false,
      tabs: mode === "tabs" ? values : false,
      temporary,
    };
  },
};

export default Assistant;
