import React from "react";
import { connect } from "react-redux";
import { Box } from "../Bitter";
import {
  SidebarContainer,
  EntitiesContainer,
  EntityContainer,
  CreateContainer,
} from "../Containers";
import { System } from "../System";
import { Functions } from "../Helpers";
import { API } from "../Modules";
import { Input, Rule, Button } from "../Bitter";
import Toast from "react-hot-toast";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      message: false,
    };
  }

  __login = async () => {
    const { username, password, loading } = this.state;

    if (username.length <= 0 || password.length <= 0) {
      Toast.error(
        "Lütfen kullanıcı adınızı ve şifrenizi eksiksiz bir biçimde giriniz."
      );
      return false;
    }

    if (loading) {
      return false;
    }

    this.setState({ loading: true });

    const login = await API.request("/auth/admin", {
      username,
      password,
    });

    if (login.success) {
      this.setState({ message: "Hoşgeldin, " + login.data.name });

      this.props.core("provider", {
        admin: {
          name: login.data.name,
          email: login.data.email,
          badge: login.data.title,
        },
      });

      this.props.core("admin", login.data.admin);

      await Functions.sleep(1000);

      if (this.props.auth) {
        this.props.auth();
      }
    }

    this.setState({ loading: false });
  };

  render() {
    const { username, password, message, loading } = this.state;

    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        align="center"
        justify="center"
      >
        {message ? (
          <Rule rule="Display" display="block" bottom={4}>
            {message}
          </Rule>
        ) : (
          <Box width={256}>
            <Box bottom={24}>
              <Rule rule="Body" display="block" bottom={4}>
                Username
              </Rule>
              <Input
                value={username}
                change={(username) => this.setState({ username })}
              />
            </Box>
            <Box bottom={24}>
              <Rule rule="Body" display="block" bottom={4}>
                Password
              </Rule>
              <Input
                type="password"
                value={password}
                change={(password) => this.setState({ password })}
              />
            </Box>
            <Button loading={loading} press={this.__login}>
              Login
            </Button>
          </Box>
        )}
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
