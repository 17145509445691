import React, { useState } from "react";

import { Colors } from "../../Helpers";
import { Box, Rule, Icon } from "../../Bitter";

import Lodash from "lodash";

const Component = ({ value, change, rules }) => {
  let data = [];

  if (typeof value === "object") {
    data = value;
  }

  const [datas, set] = useState(data);
  const { labels = {}, move = true, trash = true } = rules;

  return (
    <Box>
      {datas.map((_, __) => (
        <Box
          display="flex"
          mode="margin"
          bottom={__ + 1 < datas.length ? 12 : 0}
        >
          <Box
            mode="margin"
            left={-4}
            right={4}
            display="flex"
            flex={1}
            direction="row"
          >
            {Object.entries(_).map(([___, ____]) => {
              return (
                <Box left={4} right={4} mode="margin" bottom={12} flex={1}>
                  <Box display="flex">
                    <Box
                      color="#F7F7F7"
                      mode="padding"
                      all={12}
                      radius={8}
                      flex={1}
                      height={64}
                      display="flex"
                      direction="column"
                      justify="center"
                    >
                      {labels[___] ? (
                        <Rule
                          bottom={4}
                          display="block"
                          rule="Small"
                          opacity={0.5}
                        >
                          {labels[___]}
                        </Rule>
                      ) : (
                        ___
                      )}
                      <input
                        style={{
                          border: "1.5px solid #F7F7F7",
                          borderRadius: 2,
                          boxSizing: "border-box",
                          width: "100%",
                          display: "block",
                          fontSize: 16,
                          fontFamily: "Programme Regular",
                          color: Colors.black,
                          outline: "none",
                          background: "none",
                          backgroundColor: "none",
                        }}
                        value={____}
                        onChange={(event) => {
                          let clone = Lodash.clone(datas);
                          clone[__][___] = event.target.value;
                          set(clone);

                          if (change) {
                            change(clone);
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box display="flex" align="center" direction="column" left={12}>
            {trash ? (
              <Icon
                size={16}
                opacity={0.5}
                icon="minus"
                press={() => {
                  let clone = Lodash.clone(datas);
                  clone.splice(__, 1);
                  set(clone);

                  if (change) {
                    change(clone);
                  }
                }}
              />
            ) : undefined}
            {move ? (
              <>
                <Icon
                  size={16}
                  opacity={0.5}
                  icon="chevron-up"
                  press={() => {
                    let clone = Lodash.clone(datas);

                    if (__ > 0) {
                      let temporary = Lodash.clone(clone[__ - 1]);
                      clone[__ - 1] = clone[__];
                      clone[__] = temporary;
                      set(clone);
                      if (change) {
                        change(clone);
                      }
                    }
                  }}
                />
                <Icon
                  size={16}
                  opacity={0.5}
                  icon="chevron-down"
                  press={() => {
                    let clone = Lodash.clone(datas);

                    if (__ < clone.length - 1) {
                      let temporary = Lodash.clone(clone[__ + 1]);
                      clone[__ + 1] = clone[__];
                      clone[__] = temporary;
                      set(clone);
                      if (change) {
                        change(clone);
                      }
                    }
                  }}
                />
              </>
            ) : undefined}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Component;
