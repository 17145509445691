import React from "react";
import { connect } from "react-redux";
import {
  Box,
  Icon,
  Rule,
  Button,
  Line,
  Input,
  Select,
  D4,
  D3,
  D2,
  D1,
  Entity,
} from "../Bitter";
import { Colors, Rules } from "../Helpers";
import { Pages, API } from "../Modules";
import { System } from "../System";
import PuffLoader from "react-spinners/PuffLoader";
import Lodash from "lodash";
import Toast from "react-hot-toast";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      entity: false,
      loading: true,
      changed: true,
    };
  }

  async componentDidMount() {
    const { settings, temporary, override } = this.props;
    if (temporary) {
      if (override) {
        let overriden = Lodash.clone(temporary);
        overriden.parameters = { ...overriden.parameters, ...override };
        this.setState({
          entity: overriden,
          loading: false,
        });
      } else {
        this.setState({ entity: temporary, loading: false });
      }
    } else {
      const entity = await API.request("/@" + settings.api + "/temporary");
      if (entity.success) {
        if (override) {
          let overriden = Lodash.clone(entity.data);
          overriden.parameters = { ...overriden.parameters, ...override };
          this.setState({ entity: overriden, loading: false });
        } else {
          this.setState({ entity: entity.data, loading: false });
        }
      }
    }
  }

  create = async () => {
    const { settings } = this.props;
    const { entity, loading } = this.state;

    if (loading) {
      return false;
    }

    this.setState({ loading: true });

    const create = await API.request("/@" + settings.api + "/create", entity);
    if (create.success) {
      Toast.success(create.message);
      await Pages.previous();
      await Pages.screen("@entity/" + settings.api, create.data.uuid);
    } else {
      this.setState({ loading: false });
    }
  };
  update = async (entity) => {
    this.setState({ entity });
    this.forceUpdate();

    console.log(entity.parameters.specifications);

    return true;
  };

  render() {
    const { settings } = this.props;
    const { entity, loading, changed } = this.state;
    const first = Pages.first();

    return (
      <Box
        width="100%"
        height="100%"
        direction="column"
        flex={1}
        display="flex"
      >
        <Box
          mode="padding"
          all={24}
          direction="row"
          align="center"
          justify="space-between"
          display="flex"
        >
          <Box direction="row" align="center" display="flex">
            {first ? undefined : (
              <Icon
                icon="chevron-left"
                right={12}
                press={() => Pages.previous()}
              />
            )}
            <Box direction="row" align="flex-end" display="flex">
              {loading ? undefined : (
                <Rule rule="Header" right={12}>
                  {settings.label(entity)}
                </Rule>
              )}
              <Rule rule="Body" opacity={0.5}>
                {System.entities[settings.api].label}
              </Rule>
            </Box>
          </Box>
          <Box direction="row" align="center" display="flex">
            <Box left={12}>
              <Button type="dark" press={this.create}>
                Tamamla
              </Button>
            </Box>
          </Box>
        </Box>
        <Line />
        <Box
          width={560}
          mode="margin"
          left="auto"
          right="auto"
          height="100%"
          overflow="auto"
        >
          {loading ? (
            <Box
              mode="padding"
              top={24}
              bottom={24}
              align="center"
              justify="center"
              display="flex"
            >
              <PuffLoader color={Colors.primary} loading={true} size={32} />
            </Box>
          ) : (
            <Box mode="padding" top={24} bottom={24} right={24} left={24}>
              {settings.blocks.map((block) =>
                block.type === "string" &&
                Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    {block.label ? (
                      <Rule rule="Body" display="block" bottom={4}>
                        {block.label}
                      </Rule>
                    ) : undefined}
                    <Input
                      value={block.value(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                    />
                  </Box>
                ) : block.type === "long" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    {block.label ? (
                      <Rule rule="Body" display="block" bottom={4}>
                        {block.label}
                      </Rule>
                    ) : undefined}
                    <Input
                      long={true}
                      value={block.value(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                    />
                  </Box>
                ) : block.type === "number" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    {block.label ? (
                      <Rule rule="Body" display="block" bottom={4}>
                        {block.label}
                      </Rule>
                    ) : undefined}
                    <Input
                      type="number"
                      value={block.value(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                    />
                  </Box>
                ) : block.type === "select" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    {block.label ? (
                      <Rule rule="Body" display="block" bottom={4}>
                        {block.label}
                      </Rule>
                    ) : undefined}
                    <Select
                      options={block.options}
                      value={block.value(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                    />
                  </Box>
                ) : block.type === "date" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    {block.label ? (
                      <Rule rule="Body" display="block" bottom={4}>
                        {block.label}
                      </Rule>
                    ) : undefined}
                  </Box>
                ) : block.type === "text" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    {block.label ? (
                      <Rule rule="Body" display="block" bottom={4}>
                        {block.label}
                      </Rule>
                    ) : undefined}
                    <Rule rule="Thin" display="block">
                      {block.value(entity)}
                    </Rule>
                  </Box>
                ) : block.type === "d4" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    <Box
                      direction="row"
                      display="flex"
                      align="center"
                      justify="space-between"
                      bottom={12}
                    >
                      {block.label ? (
                        <Rule rule="Body" display="block">
                          {block.label}
                        </Rule>
                      ) : undefined}
                      {block.rules(entity) ? (
                        block.rules(entity).create === true ? (
                          <Icon
                            icon="plus"
                            press={() => {
                              let clone = Lodash.clone(entity);
                              clone.parameters[block.key] =
                                clone.parameters[block.key] === undefined
                                  ? []
                                  : clone.parameters[block.key];
                              clone.parameters[block.key].push("");
                              this.update(clone);
                            }}
                          />
                        ) : undefined
                      ) : undefined}
                    </Box>
                    <D4
                      value={block.value(entity)}
                      rules={block.rules(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                    />
                  </Box>
                ) : block.type === "d3" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    <Box
                      direction="row"
                      display="flex"
                      align="center"
                      justify="space-between"
                      bottom={12}
                    >
                      {block.label ? (
                        <Rule rule="Body" display="block">
                          {block.label}
                        </Rule>
                      ) : undefined}
                      {block.rules(entity).create === false ? undefined : (
                        <Icon
                          icon="plus"
                          press={() => {
                            let clone = Lodash.clone(entity);
                            let temporary = {};
                            Lodash.each(
                              block.rules(entity).labels,
                              (__, ___) => (temporary[___] = "")
                            );
                            clone.parameters[block.key] =
                              clone.parameters[block.key] === undefined
                                ? []
                                : clone.parameters[block.key];
                            clone.parameters[block.key].push(temporary);
                            this.update(clone);
                          }}
                        />
                      )}
                    </Box>
                    <D3
                      value={block.value(entity)}
                      rules={block.rules(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                    />
                  </Box>
                ) : block.type === "d2" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    <Box
                      direction="row"
                      display="flex"
                      align="center"
                      justify="space-between"
                      bottom={12}
                    >
                      {block.label ? (
                        <Rule rule="Body" display="block">
                          {block.label}
                        </Rule>
                      ) : undefined}
                      {block.rules(entity).create === false ? undefined : (
                        <Icon
                          icon="plus"
                          press={() => {
                            let clone = Lodash.clone(entity);
                            clone.parameters[block.key].push("");
                            this.update(clone);
                          }}
                        />
                      )}
                    </Box>
                    <D2
                      value={block.value(entity)}
                      rules={block.rules(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                    />
                  </Box>
                ) : block.type === "d1" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    <Box
                      direction="row"
                      display="flex"
                      align="center"
                      justify="space-between"
                      bottom={12}
                    >
                      {block.label ? (
                        <Rule rule="Body" display="block">
                          {block.label}
                        </Rule>
                      ) : undefined}
                      {block.rules(entity).create === false ? undefined : (
                        <Icon
                          icon="plus"
                          press={() => {
                            let clone = Lodash.clone(entity);
                            let temporary = {};
                            Lodash.each(
                              block.rules(entity).labels,
                              (__, ___) => (temporary[___] = "")
                            );
                            clone.parameters[block.key] =
                              clone.parameters[block.key] === undefined
                                ? []
                                : clone.parameters[block.key];
                            clone.parameters[block.key].push(temporary);
                            this.update(clone);
                          }}
                        />
                      )}
                    </Box>
                    <D1
                      value={block.value(entity)}
                      rules={block.rules(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                    />
                  </Box>
                ) : block.type === "entity" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    <Box
                      direction="row"
                      display="flex"
                      align="center"
                      justify="space-between"
                      bottom={12}
                    >
                      {block.label ? (
                        <Rule rule="Body" display="block">
                          {block.label}
                        </Rule>
                      ) : undefined}
                    </Box>
                    <Entity
                      value={block.value(entity)}
                      rules={block.rules(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                      changed={changed}
                    />
                  </Box>
                ) : undefined
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
