import React from "react";

import { Box, Text } from "../../Bitter";
import { Colors } from "../../Helpers";

const Component = props => {
  return (
    <Box
      color={Colors.primary}
      height={20}
      left={4}
      right={4}
      align="center"
      justify="center"
      display="inline-flex"
      mode="padding"
      radius={2}
      {...props}
    >
      <Text color={Colors.white} size={12} line={20}>
        {props.children}
      </Text>
    </Box>
  );
};

export default Component;
