import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

import { Box, Text } from "../../Bitter";
import { Colors } from "../../Helpers";

const Component = (props) => {
  const {
    size = "medium",
    type = "dark",
    press = true,
    loading = false,
  } = props;

  const HEIGHTS = {
    small: 24,
    medium: 32,
    large: 40,
  };
  const SPACINGS = {
    small: 8,
    medium: 12,
    large: 12,
  };
  const COLORS = {
    white: Colors.black,
    disabled: Colors.black,
    dark: Colors.white,
    icon: Colors.black,
  };
  const BACKGROUNDS = {
    white: Colors.white,
    disabled: "#F7F7F7",
    dark: Colors.black,
    icon: "#F7F7F7",
  };

  return (
    <Box
      color={BACKGROUNDS[type]}
      height={HEIGHTS[size]}
      left={SPACINGS[size]}
      right={SPACINGS[size]}
      align="center"
      justify="center"
      display="inline-flex"
      mode="padding"
      radius={2}
      press={loading ? undefined : press}
      {...props}
    >
      {loading ? (
        <BeatLoader
          color={COLORS[type]}
          loading={true}
          size={HEIGHTS[size] / 6}
        />
      ) : (
        <Text
          color={COLORS[type]}
          size={14}
          family="Programme Bold"
          line={HEIGHTS[size]}
        >
          {props.children}
        </Text>
      )}
    </Box>
  );
};

export default Component;
