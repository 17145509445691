const Rules = {
  get: (rules, key = false, vanguard = false) => {
    try {
      if (rules) {
        return rules[key];
      }
      return vanguard;
    } catch (Error) {
      return vanguard;
    }
  },
};

export default Rules;
