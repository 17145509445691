import React from "react";

import { Colors } from "../../Helpers";
import { Box, Rule, Button, Search } from "../../Bitter";
import { API, Pages } from "../../Modules";
import { System } from "../../System";
import PuffLoader from "react-spinners/PuffLoader";
import Toast from "react-hot-toast";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      entity: false,
      entities: [],
      choose: false,
      search: "",
    };
  }

  async componentDidMount() {
    const { value, rules } = this.props;
    const entities = await API.request("/@" + rules.api + "/read", {
      temporary: true,
    });
    if (entities.success) {
      this.setState({ entities: entities.data });
      entities.data.map((entity) => {
        if (entity.uuid === value) {
          this.setState({ entity });
        }

        return true;
      });

      this.setState({ loading: false });
    }
  }

  render() {
    const { entities, entity, loading, choose, search } = this.state;
    const { change, rules, changed } = this.props;
    const settings = System.entities[rules.api];

    return (
      <>
        {choose ? (
          <Box
            style={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: "100%",
              height: "100%",
              alignItems: "flex-end",
              justifyContent: "center",
              backgroundColor: "rgba(10,15,13,0.10)",
              padding: 24,
              boxSizing: "border-box",
              zIndex: 2,
            }}
          >
            <Box
              mode="padding"
              all={16}
              radius={8}
              shadow="small"
              color={Colors.white}
              width={512}
            >
              <Box
                display="flex"
                align="flex-start"
                justify="space-between"
                bottom={12}
              >
                <Rule rule="Title">{settings.label}</Rule>
                {settings.rules.create ? (
                  <Box left={12}>
                    <Button
                      type="dark"
                      press={
                        !changed
                          ? () =>
                              Pages.screen(
                                "@create/" + settings.api,
                                false,
                                settings.temporary
                              )
                          : () =>
                              Toast((toast) => (
                                <Box display="flex" align="flex-start">
                                  <Box mode="padding" right={12} flex={1}>
                                    Kaydedilmemiş değişiklikler kaybolacaktır.
                                  </Box>
                                  <Button
                                    type="dark"
                                    press={() => {
                                      Toast.dismiss(toast.id);
                                      Pages.screen(
                                        "@create/" + settings.api,
                                        false,
                                        settings.temporary
                                      );
                                    }}
                                  >
                                    Tamam
                                  </Button>
                                </Box>
                              ))
                      }
                    >
                      Oluştur
                    </Button>
                  </Box>
                ) : undefined}
              </Box>
              <Box bottom={12}>
                <Search
                  value={search}
                  change={(search) => this.setState({ search })}
                />
              </Box>
              {entities.length > 0 ? (
                <Box
                  radius={8}
                  shadow="medium"
                  mode="padding"
                  left={16}
                  top={8}
                  bottom={8}
                  right={16}
                  style={{ maxHeight: 512, overflowY: "auto" }}
                >
                  {entities.map((entity) =>
                    search.length > 0 ? (
                      JSON.stringify(entity).includes(search)
                    ) : true ? (
                      <Box
                        key={entity.uuid}
                        mode="padding"
                        top={8}
                        bottom={8}
                        display="flex"
                        align="center"
                        direction="row"
                      >
                        {settings.columns.map((column) =>
                          column.type === "identifier" ? (
                            <Box
                              flex={1}
                              display="flex"
                              direction="column"
                              mode="padding"
                              right={16}
                            >
                              <Rule rule="Body" bottom={4}>
                                {column.value(entity)}
                              </Rule>
                              <Rule rule="Small" opacity={0.5}>
                                {entity.uuid}
                              </Rule>
                            </Box>
                          ) : undefined
                        )}
                        <Button
                          type="disabled"
                          size="small"
                          press={() => {
                            this.setState({ entity, choose: false });
                            if (change) {
                              change(entity.uuid);
                            }
                          }}
                        >
                          Seç
                        </Button>
                      </Box>
                    ) : undefined
                  )}
                </Box>
              ) : undefined}
            </Box>
          </Box>
        ) : undefined}
        <Box
          shadow="medium"
          radius={8}
          mode="padding"
          all={16}
          display="flex"
          direction="row"
          align="center"
          justify="space-between"
        >
          {loading ? (
            <Box
              flex={1}
              align="center"
              justify="center"
              direction="row"
              display="flex"
            >
              <Box width={42} height={42}>
                <PuffLoader color={Colors.primary} loading={true} size={32} />
              </Box>
            </Box>
          ) : (
            <>
              <Box>
                {entity ? (
                  <>
                    <Rule rule="Body" display="block" bottom={4}>
                      {rules.label ? rules.label(entity) : entity.uuid}
                    </Rule>
                    <Rule rule="Small" display="block" opacity={0.5}>
                      {System.definitions[rules.api]}
                    </Rule>
                  </>
                ) : (
                  <>
                    <Rule rule="Thin" display="block" bottom={4}>
                      Seçilmedi
                    </Rule>
                    <Rule rule="Small" display="block" opacity={0.5}>
                      {System.definitions[rules.api]}
                    </Rule>
                  </>
                )}
              </Box>
              {rules.change !== false ? (
                <Box display="flex" align="center" direction="row">
                  {entity ? (
                    <Button
                      type="disabled"
                      press={() => {
                        this.setState({ entity: false });
                        if (change) {
                          change("");
                        }
                      }}
                    >
                      Temizle
                    </Button>
                  ) : undefined}
                  <Box left={12}>
                    <Button
                      press={() => this.setState({ choose: true })}
                      type="dark"
                    >
                      {entity ? "Değiştir" : "Seç"}
                    </Button>
                  </Box>
                </Box>
              ) : entity ? (
                <Box left={12}>
                  <Button
                    press={() =>
                      Pages.screen("@entity/" + rules.api, entity.uuid, false)
                    }
                    type="dark"
                  >
                    Göster
                  </Button>
                </Box>
              ) : undefined}
            </>
          )}
        </Box>
      </>
    );
  }
}

export default Component;
