import React from "react";

const SHADOWS = {
  small: "0px 1px 2px rgba(10, 15, 13, 0.2)",
  medium: "0px 1px 4px rgba(10, 15, 13, 0.15)",
  large: "0px 2px 16px rgba(10, 15, 13, 0.1)"
};

const Component = ({
  children,
  top = 0,
  left = 0,
  bottom = 0,
  right = 0,
  flex,
  align,
  justify,
  direction,
  wrap,
  color = "transparent",
  radius = 0,
  shadow = false,
  height,
  width,
  style,
  press,
  opacity = 1,
  mode = "margin",
  position = "relative",
  overflow,
  display = "block",
  all = false,
  border,
  styling
}) => {
  return (
    <div
      onClick={press}
      style={{
        height,
        width,
        opacity,
        overflow,
        display,
        border,
        cursor: press ? "pointer" : undefined,
        backgroundColor: color,
        borderRadius: radius,
        flex: flex ? flex : undefined,
        flexDirection: direction,
        flexWrap: wrap,
        alignItems: align,
        justifyContent: justify,
        paddingTop: mode === "padding" ? (all ? all : top) : undefined,
        paddingLeft: mode === "padding" ? (all ? all : left) : undefined,
        paddingBottom: mode === "padding" ? (all ? all : bottom) : undefined,
        paddingRight: mode === "padding" ? (all ? all : right) : undefined,
        marginTop: mode === "margin" ? (all ? all : top) : undefined,
        marginLeft: mode === "margin" ? (all ? all : left) : undefined,
        marginBottom: mode === "margin" ? (all ? all : bottom) : undefined,
        marginRight: mode === "margin" ? (all ? all : right) : undefined,
        top: mode === "position" ? (all ? all : top) : undefined,
        left: mode === "position" ? (all ? all : left) : undefined,
        bottom: mode === "position" ? (all ? all : bottom) : undefined,
        right: mode === "position" ? (all ? all : right) : undefined,
        boxShadow: shadow
          ? SHADOWS[shadow]
            ? SHADOWS[shadow]
            : shadow
          : undefined,
        position,
        boxSizing: "border-box",
        ...style
      }}
      className={styling}
    >
      {children}
    </div>
  );
};

export default Component;
