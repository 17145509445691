import React from "react";
import { connect } from "react-redux";
import {
  Box,
  Icon,
  Rule,
  Button,
  Line,
  Input,
  Select,
  D4,
  D3,
  D2,
  D1,
  Entity,
  Entities,
  Editor,
} from "../Bitter";
import { Colors, Rules } from "../Helpers";
import { Pages, API } from "../Modules";
import { System } from "../System";
import PuffLoader from "react-spinners/PuffLoader";
import Lodash from "lodash";
import Toast from "react-hot-toast";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      entity: false,
      loading: true,
      changed: false,
      updating: false,
    };
  }

  async componentDidMount() {
    const { settings } = this.props;
    const { data, fast } = this.props.redux;
    let request = {};
    request[settings.api] = this.props.entity;
    request.temporary = true;

    if (data) {
      Pages.data(data);
      this.setState({ entity: data, loading: false });
      this.setState({ updating: true });
      this.slow();
    }

    if (fast) {
      Pages.data(fast);
      this.setState({ entity: fast, loading: false });
      this.setState({ updating: true });
      this.slow();
    }

    if (!data && !fast) {
      const entity = await API.request("/@" + settings.api + "/read", request);
      if (entity.success) {
        Pages.data(entity.data);
        this.setState({ entity: entity.data, loading: false });
      }
    }
  }

  slow = async () => {
    const { settings } = this.props;

    let request = {};
    request[settings.api] = this.props.entity;
    request.temporary = true;

    const slow = await API.request("/@" + settings.api + "/read", request);
    if (slow.success) {
      if (this.state.changed) {
        Pages.data(slow.data);
        this.setState({ entity: slow.data, loading: false });
      }
    }
    this.setState({ updating: false });
  };

  trash = async () => {
    const { settings } = this.props;
    const { entity, loading } = this.state;

    if (loading) {
      return false;
    }

    this.setState({ loading: true });

    let request = Lodash.clone(entity);
    request[settings.api] = request.uuid;

    const trash = await API.request("/@" + settings.api + "/delete", request);
    if (trash.success) {
      Toast.success(trash.message);
      await Pages.previous();
    } else {
      this.setState({ loading: false });
    }
  };
  save = async () => {
    const { settings } = this.props;
    const { entity, loading } = this.state;

    if (loading) {
      return false;
    }

    this.setState({ loading: true, changed: false });

    let request = Lodash.clone(entity);
    request[settings.api] = request.uuid;

    console.log({ request });

    const save = await API.request("/@" + settings.api + "/update", request);
    if (save.success) {
      Pages.data(save.data);
      Toast.success(save.message);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  };
  update = async (entity) => {
    this.setState({ entity, changed: true });
    this.forceUpdate();

    return true;
  };

  force = () => {
    this.forceUpdate();
  };

  render() {
    const { settings } = this.props;
    const { entity, loading, changed, updating } = this.state;
    const first = Pages.first();

    return (
      <Box
        width="100%"
        height="100%"
        direction="column"
        flex={1}
        display="flex"
      >
        {updating ? (
          <Box style={{ position: "fixed", bottom: 72, right: 72 }}>
            <PuffLoader color={Colors.black} loading={true} size={32} />
          </Box>
        ) : undefined}
        <Box
          mode="padding"
          all={24}
          direction="row"
          align="center"
          justify="space-between"
          display="flex"
        >
          <Box direction="row" align="center" display="flex">
            {first ? undefined : (
              <Icon
                icon="chevron-left"
                right={12}
                press={() => Pages.previous()}
              />
            )}
            <Box direction="row" align="flex-end" display="flex">
              {loading ? undefined : (
                <Rule rule="Header" right={12}>
                  {settings.label(entity)}
                </Rule>
              )}
              <Rule rule="Body" opacity={0.5}>
                {System.entities[settings.api].label}
              </Rule>
            </Box>
          </Box>
          <Box direction="row" align="center" display="flex">
            {settings.rules.delete ? (
              <Box left={12}>
                <Button
                  type="disabled"
                  press={() =>
                    Toast((toast) => (
                      <Box display="flex" align="flex-start">
                        <Box mode="padding" right={12} flex={1}>
                          Silmek istediğinizden emin misiniz? Bu işlem geri
                          alınamaz.
                        </Box>
                        <Button
                          type="dark"
                          press={() => {
                            Toast.dismiss(toast.id);
                            this.trash();
                          }}
                        >
                          Evet
                        </Button>
                      </Box>
                    ))
                  }
                >
                  Sil
                </Button>
              </Box>
            ) : undefined}
            {settings.rules.update ? (
              <Box left={12}>
                <Button type="dark" press={this.save}>
                  {!changed ? "Kaydedildi" : "Kaydet"}
                </Button>
              </Box>
            ) : undefined}
          </Box>
        </Box>
        <Line />
        <Box
          width={720}
          mode="margin"
          left="auto"
          right="auto"
          height="100%"
          overflow="auto"
        >
          {loading ? (
            <Box
              mode="padding"
              top={24}
              bottom={24}
              align="center"
              justify="center"
              display="flex"
            >
              <PuffLoader color={Colors.primary} loading={true} size={32} />
            </Box>
          ) : (
            <Box mode="padding" top={24} bottom={24} right={24} left={24}>
              {settings.blocks.map((block) =>
                block.type === "string" &&
                Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    {block.label ? (
                      <Rule rule="Body" display="block" bottom={4}>
                        {block.label}
                      </Rule>
                    ) : undefined}
                    <Input
                      value={block.value(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                    />
                  </Box>
                ) : block.type === "long" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    {block.label ? (
                      <Rule rule="Body" display="block" bottom={4}>
                        {block.label}
                      </Rule>
                    ) : undefined}
                    <Input
                      long={true}
                      value={block.value(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                    />
                  </Box>
                ) : block.type === "number" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    {block.label ? (
                      <Rule rule="Body" display="block" bottom={4}>
                        {block.label}
                      </Rule>
                    ) : undefined}
                    <Input
                      type="number"
                      value={block.value(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                    />
                  </Box>
                ) : block.type === "select" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    {block.label ? (
                      <Rule rule="Body" display="block" bottom={4}>
                        {block.label}
                      </Rule>
                    ) : undefined}
                    <Select
                      options={block.options}
                      value={block.value(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                    />
                  </Box>
                ) : block.type === "date" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    {block.label ? (
                      <Rule rule="Body" display="block" bottom={4}>
                        {block.label}
                      </Rule>
                    ) : undefined}
                  </Box>
                ) : block.type === "text" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    {block.label ? (
                      <Rule rule="Body" display="block" bottom={4}>
                        {block.label}
                      </Rule>
                    ) : undefined}
                    <Rule rule="Thin" display="block">
                      {block.value(entity)}
                    </Rule>
                  </Box>
                ) : block.type === "d4" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    <Box
                      direction="row"
                      display="flex"
                      align="center"
                      justify="space-between"
                      bottom={12}
                    >
                      {block.label ? (
                        <Rule rule="Body" display="block">
                          {block.label}
                        </Rule>
                      ) : undefined}
                      {block.rules(entity) ? (
                        block.rules(entity).create === true ? (
                          <Icon
                            icon="plus"
                            press={() => {
                              let clone = Lodash.clone(entity);
                              clone.parameters[block.key] =
                                clone.parameters[block.key] === undefined
                                  ? []
                                  : clone.parameters[block.key];
                              clone.parameters[block.key].push("");
                              this.update(clone);
                            }}
                          />
                        ) : undefined
                      ) : undefined}
                    </Box>
                    <D4
                      value={block.value(entity)}
                      rules={block.rules(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                    />
                  </Box>
                ) : block.type === "d3" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    <Box
                      direction="row"
                      display="flex"
                      align="center"
                      justify="space-between"
                      bottom={12}
                    >
                      {block.label ? (
                        <Rule rule="Body" display="block">
                          {block.label}
                        </Rule>
                      ) : undefined}
                      {block.rules(entity).create === false ? undefined : (
                        <Icon
                          icon="plus"
                          press={() => {
                            let clone = Lodash.clone(entity);
                            let temporary = {};
                            Lodash.each(
                              block.rules(entity).labels,
                              (__, ___) => (temporary[___] = "")
                            );
                            clone.parameters[block.key] =
                              clone.parameters[block.key] === undefined
                                ? []
                                : clone.parameters[block.key];
                            clone.parameters[block.key].push(temporary);
                            this.update(clone);
                          }}
                        />
                      )}
                    </Box>
                    <D3
                      value={block.value(entity)}
                      rules={block.rules(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                    />
                  </Box>
                ) : block.type === "d2" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    <Box
                      direction="row"
                      display="flex"
                      align="center"
                      justify="space-between"
                      bottom={12}
                    >
                      {block.label ? (
                        <Rule rule="Body" display="block">
                          {block.label}
                        </Rule>
                      ) : undefined}
                      {block.rules(entity).create === false ? undefined : (
                        <Icon
                          icon="plus"
                          press={() => {
                            let clone = Lodash.clone(entity);
                            clone.parameters[block.key].push("");
                            this.update(clone);
                          }}
                        />
                      )}
                    </Box>
                    <D2
                      value={block.value(entity)}
                      rules={block.rules(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                    />
                  </Box>
                ) : block.type === "d1" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    <Box
                      direction="row"
                      display="flex"
                      align="center"
                      justify="space-between"
                      bottom={12}
                    >
                      {block.label ? (
                        <Rule rule="Body" display="block">
                          {block.label}
                        </Rule>
                      ) : undefined}
                      {block.rules(entity).create === false ? undefined : (
                        <Icon
                          icon="plus"
                          press={() => {
                            let clone = Lodash.clone(entity);
                            let temporary = {};
                            Lodash.each(
                              block.rules(entity).labels,
                              (__, ___) => (temporary[___] = "")
                            );
                            clone.parameters[block.key] =
                              clone.parameters[block.key] === undefined
                                ? []
                                : clone.parameters[block.key];
                            clone.parameters[block.key].push(temporary);
                            this.update(clone);
                          }}
                        />
                      )}
                    </Box>
                    <D1
                      value={block.value(entity)}
                      rules={block.rules(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                    />
                  </Box>
                ) : block.type === "editor" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    <Box
                      direction="row"
                      display="flex"
                      align="center"
                      justify="space-between"
                      bottom={12}
                    >
                      {block.label ? (
                        <Rule rule="Body" display="block">
                          {block.label}
                        </Rule>
                      ) : undefined}
                    </Box>
                    <Editor
                      value={block.value(entity)}
                      rules={block.rules(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                    />
                  </Box>
                ) : block.type === "custom" ? (
                  block.jsx(entity, this)
                ) : block.type === "entity" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    <Box
                      direction="row"
                      display="flex"
                      align="center"
                      justify="space-between"
                      bottom={12}
                    >
                      {block.label ? (
                        <Rule rule="Body" display="block">
                          {block.label}
                        </Rule>
                      ) : undefined}
                    </Box>
                    <Entity
                      value={block.value(entity)}
                      rules={block.rules(entity)}
                      change={(value) =>
                        this.update(block.change(entity, block.key, value))
                      }
                      changed={changed}
                    />
                  </Box>
                ) : block.type === "entities" &&
                  Rules.get(block.rules(entity), "display", true) ? (
                  <Box bottom={24}>
                    <Box
                      direction="row"
                      display="flex"
                      align="center"
                      justify="space-between"
                      bottom={12}
                    >
                      {block.label ? (
                        <Rule rule="Body" display="block">
                          {block.label}
                        </Rule>
                      ) : undefined}
                      {block.rules(entity).create === false ? undefined : (
                        <Icon
                          icon="plus"
                          press={
                            !changed
                              ? () =>
                                  Pages.screen(
                                    "@create/" + block.rules(entity).api,
                                    false,
                                    false,
                                    block.rules(entity).override
                                  )
                              : () =>
                                  Toast((toast) => (
                                    <Box display="flex" align="flex-start">
                                      <Box mode="padding" right={12} flex={1}>
                                        Kaydedilmemiş değişiklikler
                                        kaybolacaktır.
                                      </Box>
                                      <Button
                                        type="dark"
                                        press={() => {
                                          Toast.dismiss(toast.id);
                                          Pages.screen(
                                            "@create/" +
                                              block.rules(entity).api,
                                            false,
                                            false,
                                            block.rules(entity).override
                                          );
                                        }}
                                      >
                                        Tamam
                                      </Button>
                                    </Box>
                                  ))
                          }
                        />
                      )}
                    </Box>
                    <Entities changed={changed} rules={block.rules(entity)} />
                  </Box>
                ) : undefined
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
