import React from "react";

import { Colors } from "../../Helpers";

const Component = ({ value, change, type = "text", long = false }) => {
  return long ? (
    <textarea
      type={type}
      style={{
        border: "1.5px solid #F7F7F7",
        borderRadius: 2,
        boxSizing: "border-box",
        width: "100%",
        display: "block",
        padding: 12,
        fontSize: 14,
        fontFamily: "Programme Regular",
        color: Colors.black,
        outline: "none",
        background: "none",
        backgroundColor: "none",
        resize: "none",
      }}
      rows={12}
      value={value}
      onChange={(event) => (change ? change(event.target.value) : {})}
    />
  ) : (
    <input
      type={type}
      style={{
        height: 40,
        border: "1.5px solid #F7F7F7",
        borderRadius: 2,
        boxSizing: "border-box",
        width: "100%",
        display: "block",
        paddingLeft: 12,
        paddingRight: 12,
        fontSize: 14,
        fontFamily: "Programme Regular",
        color: Colors.black,
        outline: "none",
        background: "none",
        backgroundColor: "none",
      }}
      value={value}
      onChange={(event) => (change ? change(event.target.value) : {})}
    />
  );
};

export default Component;
