import React, { useState } from "react";

import { Colors } from "../../Helpers";
import { Box, Rule } from "../../Bitter";

import Lodash from "lodash";

const Component = ({ value, change, rules }) => {
  let data = [];

  if (typeof value === "object") {
    data = value;
  }

  const [datas, set] = useState(data);
  const { labels = {} } = rules;

  return (
    <Box>
      {Object.entries(datas).map(([__, ___]) => {
        return (
          <Box mode="margin" bottom={12}>
            <Box display="flex">
              <Box
                color="#F7F7F7"
                mode="padding"
                all={12}
                radius={8}
                flex={1}
                height={64}
                display="flex"
                direction="column"
                justify="center"
              >
                {labels[__] ? (
                  <Rule bottom={4} display="block" rule="Small" opacity={0.5}>
                    {labels[__]}
                  </Rule>
                ) : (
                  __
                )}
                <input
                  style={{
                    border: "1.5px solid #F7F7F7",
                    borderRadius: 2,
                    boxSizing: "border-box",
                    width: "100%",
                    display: "block",
                    fontSize: 16,
                    fontFamily: "Programme Regular",
                    color: Colors.black,
                    outline: "none",
                    background: "none",
                    backgroundColor: "none",
                  }}
                  value={___}
                  onChange={(event) => {
                    let clone = Lodash.clone(datas);
                    clone[__] = event.target.value;
                    set(clone);

                    if (change) {
                      change(clone);
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default Component;
