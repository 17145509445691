import React from "react";
import { connect } from "react-redux";
import { Box, Text, Avatar, Rule, Badge, Line } from "../Bitter";
import { Resources } from "../Helpers";
import { Pages } from "../Modules";
import { System } from "../System";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { screen, provider } = this.props.redux;

    return (
      <Box width={320} height="100%" shadow="small">
        <Box mode="padding" all={24}>
          <Box align="flex-start" display="flex">
            <Text size={20} family="Programme Bold" right={4}>
              {Resources.app}
            </Text>
            <Text size={14} family="Programme Regular" opacity={0.5}>
              Demo
            </Text>
          </Box>
        </Box>
        <Line />
        <Box
          mode="padding"
          all={24}
          display="flex"
          align="center"
          justify="space-between"
        >
          <Box align="flex-start" display="flex">
            <Avatar />
            <Box left={12} right={12}>
              <Box align="flex-start" display="flex">
                <Rule rule="Body" right={8}>
                  {provider.admin.name}
                </Rule>
                <Badge>{provider.admin.badge}</Badge>
              </Box>
              <Rule rule="Small" opacity={0.5}>
                {provider.admin.email}
              </Rule>
            </Box>
          </Box>
        </Box>
        <Line />
        {System.menu.map((menu) => (
          <Box
            mode="padding"
            top={24}
            left={24}
            right={24}
            display="flex"
            align="center"
            opacity={screen === "@entities/" + menu.value ? 1 : 0.5}
            press={() => Pages.screen("@entities/" + menu.value)}
          >
            {menu.label}
          </Box>
        ))}
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
