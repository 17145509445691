import React from "react";
import { connect } from "react-redux";
import { Box } from "../Bitter";
import {
  SidebarContainer,
  EntitiesContainer,
  EntityContainer,
  CreateContainer,
} from "../Containers";
import { System } from "../System";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { screen, entity, temporary, override, data } = this.props.redux;

    return (
      <Box width="100%" height="100%" overflow="auto" display="flex">
        <SidebarContainer />
        {screen.includes("@entities/") ? (
          <EntitiesContainer
            settings={System.entities[screen.replace("@entities/", "")]}
          />
        ) : screen.includes("@entity/") ? (
          <EntityContainer
            settings={System.entity[screen.replace("@entity/", "")]}
            screen={screen}
            entity={entity}
          />
        ) : screen.includes("@create/") ? (
          <CreateContainer
            settings={System.entity[screen.replace("@create/", "")]}
            screen={screen}
            entity={entity}
            temporary={temporary}
            override={override}
          />
        ) : undefined}
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
