import React from "react";
import { connect } from "react-redux";
import { Box, Icon, Rule, Button, Line, Search } from "../Bitter";
import { Colors } from "../Helpers";
import { Pages, API } from "../Modules";
import PuffLoader from "react-spinners/PuffLoader";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      entities: [],
      loading: true,
      search: "",
      updating: false,
    };
  }

  async componentDidMount() {
    const { settings } = this.props;
    const { data } = this.props.redux;

    let parameters = {
      temporary: true,
    };

    if (settings.override) {
      settings.override.map((override) => {
        parameters[override.key] = override.value;
      });
    }

    if (data) {
      Pages.data(data);
      this.setState({ entities: data, loading: false, updating: true });
      this.slow();
    } else {
      const entities = await API.request(
        "/@" + settings.api + "/read",
        parameters
      );
      if (entities.success) {
        Pages.data(entities.data);
        this.setState({ entities: entities.data, loading: false });
      }
    }
  }

  slow = async () => {
    const { settings } = this.props;
    const { data } = this.props.redux;

    let parameters = {
      temporary: true,
    };

    if (settings.override) {
      settings.override.map((override) => {
        parameters[override.key] = override.value;
      });
    }

    const entities = await API.request(
      "/@" + settings.api + "/read",
      parameters
    );
    if (entities.success) {
      Pages.data(entities.data);
      this.setState({ entities: entities.data, loading: false });
    }

    this.setState({ updating: false });
  };

  render() {
    const { settings } = this.props;
    const { entities, loading, search, updating } = this.state;
    const first = Pages.first();

    let results = entities;

    if (settings.order) {
      results.sort(settings.order);
    }

    return (
      <Box
        width="100%"
        height="100%"
        direction="column"
        flex={1}
        display="flex"
      >
        {updating ? (
          <Box style={{ position: "fixed", bottom: 72, right: 72 }}>
            <PuffLoader color={Colors.black} loading={true} size={32} />
          </Box>
        ) : undefined}
        <Box
          mode="padding"
          all={24}
          direction="row"
          align="center"
          justify="space-between"
          display="flex"
        >
          <Box direction="row" align="center" display="flex">
            {first ? undefined : (
              <Icon
                press={() => Pages.previous()}
                icon="chevron-left"
                right={12}
              />
            )}
            <Box direction="row" align="flex-end" display="flex">
              <Rule rule="Header" right={12}>
                {settings.label}
              </Rule>
            </Box>
          </Box>
          <Box direction="row" align="center" display="flex">
            {settings.rules.create ? (
              <Box left={12}>
                <Button
                  type="dark"
                  press={() =>
                    Pages.screen(
                      "@create/" + settings.api,
                      false,
                      settings.temporary
                    )
                  }
                >
                  Oluştur
                </Button>
              </Box>
            ) : undefined}
          </Box>
        </Box>
        <Line />
        <Box width="100%" mode="padding" all={24} height="100%" overflow="auto">
          {loading ? (
            <Box
              mode="padding"
              all={16}
              align="center"
              justify="center"
              display="flex"
            >
              <PuffLoader color={Colors.primary} loading={true} size={32} />
            </Box>
          ) : entities.length > 0 ? (
            <>
              <Box bottom={12}>
                <Search
                  value={search}
                  change={(search) => this.setState({ search })}
                />
              </Box>
              <Box
                radius={8}
                shadow="medium"
                mode="padding"
                left={16}
                top={8}
                bottom={8}
                right={16}
              >
                {results.map((entity) =>
                  search.length > 0 ? (
                    JSON.stringify(entity).includes(search)
                  ) : true ? (
                    <Box
                      key={entity.uuid}
                      mode="padding"
                      top={8}
                      bottom={8}
                      display="flex"
                      align="center"
                      direction="row"
                    >
                      {settings.columns.map((column) =>
                        column.type === "identifier" ? (
                          <Box
                            flex={1}
                            display="flex"
                            direction="column"
                            mode="padding"
                            right={16}
                          >
                            <Rule rule="Tiny" bottom={4} opacity={0.25}>
                              {column.label}
                            </Rule>
                            <Rule rule="Body" bottom={4}>
                              {column.value(entity)}
                            </Rule>
                            <Rule rule="Small" opacity={0.5}>
                              {entity.uuid}
                            </Rule>
                          </Box>
                        ) : column.type === "string" ? (
                          <Box
                            flex={1}
                            display="flex"
                            direction="column"
                            mode="padding"
                            right={16}
                          >
                            <Rule rule="Tiny" bottom={4} opacity={0.25}>
                              {column.label}
                            </Rule>
                            <Rule rule="Thin">{column.value(entity)}</Rule>
                          </Box>
                        ) : column.type === "entity" ? (
                          <Box
                            flex={1}
                            display="flex"
                            direction="column"
                            mode="padding"
                            right={16}
                            press={() =>
                              Pages.screen(
                                "@entity/" + column.rules(entity).api,
                                column.value(entity),
                                false
                              )
                            }
                          >
                            <Rule rule="Tiny" bottom={4} opacity={0.25}>
                              {column.label}
                            </Rule>
                            <Rule rule="Thin" color={Colors.primary}>
                              {column.value(entity)}
                            </Rule>
                          </Box>
                        ) : column.type === "long" ? (
                          <Box
                            flex={1}
                            display="flex"
                            direction="column"
                            mode="padding"
                            right={16}
                          >
                            <Rule rule="Tiny" bottom={4} opacity={0.25}>
                              {column.label}
                            </Rule>
                            <Rule
                              rule="Thin"
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {column.value(entity)}
                            </Rule>
                          </Box>
                        ) : undefined
                      )}
                      <Button
                        type="disabled"
                        size="small"
                        press={() =>
                          Pages.screen(
                            "@entity/" + settings.api,
                            entity.uuid,
                            settings.temporary
                              ? settings.temporary(entity)
                              : false,
                            false,
                            entity
                          )
                        }
                      >
                        Detaylar
                      </Button>
                    </Box>
                  ) : undefined
                )}
              </Box>
            </>
          ) : undefined}
        </Box>
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
