import React from "react";
import { Box, Rule, Button } from "../Bitter";
import { Resources } from "../Helpers";
import { API } from "../Modules";
import Toast from "react-hot-toast";

const STYLE = {
  holder: {
    width: "100%",
    height: "auto",
    marginBottom: 24,
    cursor: "pointer",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
    paddingTop: "53%",
  },
  image: {
    width: "100%",
    height: "auto",
    display: "block",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    pointerEvents: "none",
  },
  hidden: {
    display: "none",
  },
};

const Component = ({ entity, system }) => {
  const file = React.createRef();

  return (
    <>
      <input
        ref={file}
        type="file"
        accept="image/png"
        style={STYLE.hidden}
        onChange={async (event) => {
          Toast((toast) => (
            <Box display="flex" align="flex-start">
              <Box mode="padding" right={12} flex={1}>
                Bu resmi yüklemek istediğinizden emin misiniz?
              </Box>
              <Button
                type="dark"
                press={() => {
                  event.stopPropagation();
                  event.preventDefault();
                  var file = event.target.files[0];
                  let reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = async () => {
                    const upload = await API.request("/image/base64", {
                      uuid: entity.uuid,
                      data: reader.result,
                    });

                    if (upload.success) {
                      Toast.success(upload.message);
                    }

                    if (upload.error) {
                      Toast.error(upload.message);
                    }

                    system.force();
                  };
                  reader.onerror = function (error) {
                    Toast.error(
                      "Resim okunurken bir sorun oluştu, lütfen resim dosyasının bozuk olmadığından emin olunuz."
                    );
                  };
                }}
              >
                Evet
              </Button>
            </Box>
          ));
        }}
      />
      <Box bottom={24}>
        <Box bottom={12}>
          <Rule rule="Body" display="block" bottom={12}>
            Preview
          </Rule>
          <Rule rule="Small" display="block" opacity={0.9} bottom={12}>
            The image should be PNG format and the height of the image should be
            minimum 600 pixels for clear resolution for Apple devices. The
            aspect ratio should be around 3/2 or 375/200 or 375/300. Note that
            due to different screen sizes and experiences we do not keep the
            aspect ratio same all the time. Some outer parts of the image could
            be cropped on different devices and screens.
          </Rule>
          <Rule rule="Small" display="block" opacity={0.9} bottom={12}>
            Please keep your main content as centered as possible.
          </Rule>
          <Rule rule="Tiny" display="block" opacity={0.8}>
            Click on the image to upload a new one, note that the image may not
            be changed instantly due to cache on your browser.
          </Rule>
        </Box>
        <div
          style={STYLE.holder}
          onClick={() => {
            if (file) {
              file.current.click();
            }
          }}
        >
          <img
            style={STYLE.image}
            src={Resources.url + "/picture/" + entity.uuid}
          />
        </div>
      </Box>
    </>
  );
};

export default Component;
