import Store from "./Store";
import { Functions } from "../Helpers";
import Lodash from "lodash";

class Pages {
  static async screen(
    screen = false,
    entity = false,
    temporary = false,
    override = false,
    fast = false
  ) {
    let history = Lodash.clone(Store.getState().history);

    history.push({ screen, entity, temporary, override, data: false, fast });

    Store.dispatch({
      type: "CORE",
      key: "history",
      value: history,
    });

    Store.dispatch({
      type: "CORE",
      key: "screen",
      value: "",
    });

    Store.dispatch({
      type: "CORE",
      key: "entity",
      value: history[history.length - 1].entity,
    });

    Store.dispatch({
      type: "CORE",
      key: "temporary",
      value: history[history.length - 1].temporary,
    });

    Store.dispatch({
      type: "CORE",
      key: "data",
      value: history[history.length - 1].data,
    });

    Store.dispatch({
      type: "CORE",
      key: "override",
      value: history[history.length - 1].override,
    });

    Store.dispatch({
      type: "CORE",
      key: "fast",
      value: history[history.length - 1].fast,
    });

    await Functions.sleep(1);

    Store.dispatch({
      type: "CORE",
      key: "screen",
      value: history[history.length - 1].screen,
    });
  }

  static async previous() {
    let history = Lodash.clone(Store.getState().history);

    history.pop();

    Store.dispatch({
      type: "CORE",
      key: "history",
      value: history,
    });

    Store.dispatch({
      type: "CORE",
      key: "screen",
      value: "",
    });

    Store.dispatch({
      type: "CORE",
      key: "entity",
      value: history[history.length - 1].entity,
    });

    Store.dispatch({
      type: "CORE",
      key: "temporary",
      value: history[history.length - 1].temporary,
    });

    Store.dispatch({
      type: "CORE",
      key: "override",
      value: history[history.length - 1].override,
    });

    Store.dispatch({
      type: "CORE",
      key: "data",
      value: history[history.length - 1].data,
    });

    Store.dispatch({
      type: "CORE",
      key: "fast",
      value: history[history.length - 1].fast,
    });

    await Functions.sleep(1);

    Store.dispatch({
      type: "CORE",
      key: "screen",
      value: history[history.length - 1].screen,
    });
  }

  static first() {
    let history = Lodash.clone(Store.getState().history);

    return history.length === 1;
  }

  static async data(data = false) {
    let history = Lodash.clone(Store.getState().history);

    if (data) {
      history[history.length - 1].data = data;

      Store.dispatch({
        type: "CORE",
        key: "history",
        value: history,
      });
    }
    return history[history.length - 1].data;
  }
}

export default Pages;
