import React from "react";

import { Box } from "../../Bitter";
import { Colors } from "../../Helpers";

const Component = props => {
  const { size = "medium" } = props;
  return size === "small" ? (
    <Box
      radius={999}
      color={Colors.secondary}
      width={24}
      height={24}
      border="2px solid white"
      style={{ minWidth: 24 }}
      {...props}
    />
  ) : (
    <Box
      radius={999}
      color={Colors.secondary}
      width={32}
      height={32}
      style={{ minWidth: 32 }}
      {...props}
    />
  );
};

export default Component;
