import React from "react";
import FeatherIcon from "feather-icons-react";
import { Box } from "../../Bitter";

const Component = props => {
  const { icon, size = 24, press = true } = props;
  return (
    <Box height={size} width={size} press={press} {...props}>
      <FeatherIcon size={size} icon={icon} />
    </Box>
  );
};

export default Component;
