import { createStore } from "redux";

const STATE = {
  admin: "ADMIN",
  screen: "@entities/journeys",
  entity: false,
  temporary: false,
  override: false,
  data: false,
  fast: false,
  history: [
    {
      screen: "",
      entity: false,
      temporary: false,
      override: false,
      data: false,
      fast: false,
    },
  ],
  provider: {
    admin: {
      name: "Relate Team",
      email: "team@therleate.app",
      badge: "Owner",
    },
  },
};

const reducer = (state = STATE, action) => {
  var holder = { ...state };
  switch (action.type) {
    case "CORE":
      holder[action.key] = action.value;
      return holder;
    default:
      return holder;
  }
};

const store = createStore(reducer);

export default store;
