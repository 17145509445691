import React from "react";

import { Colors } from "../../Helpers";
import { Box, Rule, Button } from "../../Bitter";
import { API, Pages } from "../../Modules";
import { System } from "../../System";
import PuffLoader from "react-spinners/PuffLoader";
import Toast from "react-hot-toast";

class Component extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      entities: [],
    };
  }

  async componentDidMount() {
    const { rules } = this.props;

    let parameters = {
      temporary: true,
    };

    if (rules.filtering) {
      rules.filtering.map((override) => {
        parameters[override.key] = override.value;
      });
    }

    const entities = await API.request("/@" + rules.api + "/read", parameters);
    if (entities.success) {
      this.setState({ entities: entities.data, loading: false });
    }
  }

  render() {
    const { loading } = this.state;
    const { rules, changed } = this.props;
    const settings = System.entities[rules.api];

    let entities = this.state.entities.filter(rules.filter);

    if (rules.order) {
      if (typeof rules.order === "object") {
        rules.order.map((o) => entities.sort(o));
      } else {
        entities.sort(rules.order);
      }
    }

    return (
      <>
        <Box
          shadow="medium"
          radius={8}
          mode="padding"
          all={16}
          display="flex"
          direction={loading ? "row" : "column"}
          align={loading ? "center" : undefined}
          justify={loading ? "space-between" : undefined}
        >
          {loading ? (
            <Box
              flex={1}
              align="center"
              justify="center"
              direction="row"
              display="flex"
            >
              <Box width={42} height={42}>
                <PuffLoader color={Colors.primary} loading={true} size={32} />
              </Box>
            </Box>
          ) : (
            <>
              {entities.length > 0
                ? entities.map((entity) => (
                    <Box
                      key={entity.uuid}
                      mode="padding"
                      top={8}
                      bottom={8}
                      display="flex"
                      flex={1}
                      align="center"
                      direction="row"
                    >
                      {settings.columns.map((column) =>
                        column.type === "identifier" ? (
                          <Box
                            flex={1}
                            display="flex"
                            direction="column"
                            mode="padding"
                            right={16}
                          >
                            <Rule rule="Body" bottom={4}>
                              {column.value(entity)}
                            </Rule>
                            <Rule rule="Small" opacity={0.5}>
                              {entity.uuid}
                            </Rule>
                          </Box>
                        ) : column.type === "string" ? (
                          <Box
                            flex={1}
                            display="flex"
                            direction="column"
                            mode="padding"
                            right={16}
                          >
                            <Rule rule="Tiny" bottom={4} opacity={0.25}>
                              {column.label}
                            </Rule>
                            <Rule rule="Thin">{column.value(entity)}</Rule>
                          </Box>
                        ) : column.type === "entity" ? (
                          <Box
                            flex={1}
                            display="flex"
                            direction="column"
                            mode="padding"
                            right={16}
                            press={() =>
                              Pages.screen(
                                "@entity/" + column.rules(entity).api,
                                column.value(entity),
                                false
                              )
                            }
                          >
                            <Rule rule="Tiny" bottom={4} opacity={0.25}>
                              {column.label}
                            </Rule>
                            <Rule rule="Thin" color={Colors.primary}>
                              {column.value(entity)}
                            </Rule>
                          </Box>
                        ) : undefined
                      )}
                      {rules.details === false ? undefined : (
                        <Button
                          type="disabled"
                          size="small"
                          press={
                            !changed
                              ? () =>
                                  Pages.screen(
                                    "@entity/" + settings.api,
                                    entity.uuid,
                                    false,
                                    false,
                                    entity
                                  )
                              : () =>
                                  Toast((toast) => (
                                    <Box display="flex" align="flex-start">
                                      <Box mode="padding" right={12} flex={1}>
                                        Kaydedilmemiş değişiklikler
                                        kaybolacaktır.
                                      </Box>
                                      <Button
                                        type="dark"
                                        press={() => {
                                          Toast.dismiss(toast.id);
                                          Pages.screen(
                                            "@entity/" + settings.api,
                                            entity.uuid
                                          );
                                        }}
                                      >
                                        Tamam
                                      </Button>
                                    </Box>
                                  ))
                          }
                        >
                          Detaylar
                        </Button>
                      )}
                    </Box>
                  ))
                : undefined}
            </>
          )}
        </Box>
      </>
    );
  }
}

export default Component;
