import axios from "axios";
import Store from "./Store";
import Toast from "react-hot-toast";
import { Resources } from "../Helpers";

class API {
  static async request(slug, temporary = {}) {
    const admin = Store.getState().admin;

    let request = temporary;
    request.url = Resources.url + slug;
    let headers = {
      Authentication: admin,
      Platform: "web",
      Device: "ADMIN",
    };

    const promise = new Promise((resolve) => {
      axios
        .post("https://api.gumenita.com/request/relate", request, {
          headers,
        })
        .then((res) => {
          console.log({
            API: {
              slug,
              request,
              headers,
              response: res.data,
            },
          });

          const data = res.data;

          if (data.error) {
            Toast.error(data.message);
          }

          resolve({
            success: data.success,
            error: data.error,
            message: data.message,
            data: data.data,
          });
        })
        .catch(() => {
          Toast.error("Internal Server Error");
          resolve({
            success: false,
            error: true,
            message: "Internal Server Error",
            data: null,
          });
        });
    });
    const result = await promise;
    return result;
  }

  static source(uuid) {
    return Resources.url + "/picture/" + uuid + ".jpg";
  }
}

export default API;
